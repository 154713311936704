$(function () {
  var swiper = new Swiper(".mySwiper", {
    slidesPerView: "auto",
    spaceBetween: 16,
    pagination: {
      el: ".swiper-pagination",
      type: "fraction",
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    loop: true,
    autoplay: {
      delay: 4000,
    },
    breakpoints: {
      // when window width is >= 640px
      spaceBetween: 21,
      768: {
        autoplay: true,
      }
    }
  });

  var catSwiper = new Swiper(".mySwiper02", {
    slidesPerView: 1,
    allowTouchMove: false,
    effect: 'fade',
    autoHeight: true,
  });
  $('.js-catBtn a').click(function () {
    $('.js-catBtn a').removeClass('is-active')
    $(this).addClass('is-active')
    catSwiper.slideTo($(this).attr('href'))
    return false;
  })
})